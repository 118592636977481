import React from 'react';
import ReviewsSection from '../components/reviews-section/ReviewsSection';

function Reviews() {
  return (
    <ReviewsSection isHomePage={false} />
  );
}

export default Reviews;
