import React from 'react'
import ProjectCards from '../components/project-cards/ProjectCards';

function Projects() {
  return (
    <ProjectCards />
  )
}

export default Projects;
