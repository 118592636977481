import React from 'react';
import ContactSection from '../components/contact-section/ContactSection';

function Contact() {
  return (
    <ContactSection isHomepage={false} />
  )
}

export default Contact;
