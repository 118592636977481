import React from 'react';
import ServicesCards from '../components/service-cards/ServicesCards';

function Services() {
  return (
    <ServicesCards />
  );
}

export default Services;
